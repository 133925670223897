import { Link } from "gatsby";
import React from "react";
import logo from '../images/cs-apple.png'

function Header() {
  // const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="bg-black">
      <div className="flex flex-wrap flex-col items-center justify-center max-w-4xl px-4 mx-auto pt-8 pb-4 md:pt-16 md:pb-8 md:px-8">
        <Link to="/" className="w-56 md:w-5/12">
          {/* <h1 className="flex items-center text-white text-center no-underline text-4xl md:text-5xl uppercase" style={{ fontFamily: 'km-bold'}}>            
              Blast: Mix Distribution
          </h1>           */}
          <img src={logo} />  
        </Link>
        {/* <h2 className="text-white text-center md:text-xl">by CreateSafe and Apple Music</h2> */}

        {/* <button
          className="items-center block px-3 py-2 text-white border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/about`,
              title: `About`,
            },
            {
              route: `/contact`,
              title: `Contact`,
            },
          ].map((link) => (
            <Link
              className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav> */}
      </div>
    </header>
  );
}

export default Header;
